// settings
import Settings from '@/config/Settings';

// components
import LoadImage from '@/Views/_Components/Helpers/Image/index.vue';

// slick carousel
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'AuthBase',

  components: {
    'oston-image': LoadImage,
    'slick-carousel': VueSlickCarousel
  },

  mounted() {
    Settings.title('Login');

    if (this.$route.query.token) {
      _.controller('auth').login({
        token: this.$route.query.token
      });
    }

    this.error = this.$route.query.error;

    if (_.model('auth').token) this.$router.push({name: 'Home'});
  },

  data: () => {
    return {
      error: ""
    }
  }

};
